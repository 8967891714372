import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Link } from 'gatsby';

import Container from 'components/Container';
import logo from 'assets/images/logo.png';

const Header = () => {
  React.useEffect(() => {
    const header = document.getElementById('EYHeader');
    const scrollCallBack = window.addEventListener('scroll', () => {
      if (window.pageYOffset > 678) {
        header.classList.add('sticky');
      } else {
        header.classList.remove('sticky');
      }
    });
    return () => {
      window.removeEventListener('scroll', scrollCallBack);
    };
  }, []);

  return (
    <header id='EYHeader'>
      <Container>
        <p>
          <Link to='/'>Enlightened for the next gen</Link>
        </p>
        <ul>
          <li>
            <AnchorLink to='/#about' title='About' stripHash>
              About
            </AnchorLink>
          </li>
          <li>
            <AnchorLink to='/#topics' title='Speakers' stripHash>
              Topics
            </AnchorLink>
          </li>
          <li>
            <Link to='/get-involved/' className='button'>
              Get involved
            </Link>
          </li>
          <li className='logo'>
            <a href='https://www.ey.com/en_gl' target='_blank' rel='noreferrer'>
              <img src={logo} alt='EY logo' />
            </a>
          </li>
        </ul>
      </Container>
    </header>
  );
};

export default Header;
