import React from 'react';

import divider from 'assets/images/path-3.png';
import footerLogo from 'assets/images/bitmap.png';
import Facebook from 'assets/images/facebook.inline.svg';
import LinkedIn from 'assets/images/linkedin.inline.svg';
import Youtube from 'assets/images/youtube.inline.svg';
import Twitter from 'assets/images/twitter.inline.svg';

import Container from 'components/Container';

const Footer = () => {
  return (
    <footer>
      <Container>
        <div className='divider'>
          <img src={divider} alt='divider' />
        </div>
        <div className='footer-wrapper'>
          <div className='footer-logo'>
            <img src={footerLogo} alt='EY Logo tageline' />
          </div>
          <div className='footer-links'>
            <div className='footer-nav'>
              <ul>
                <li>
                  <a
                    href='https://www.ey.com/en_gl/legal-statement'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Legal and privacy
                  </a>
                </li>
                <li>
                  <a
                    href='https://login.ey.com/myey/login'
                    target='_blank'
                    rel='noreferrer'
                  >
                    My EY
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.ey.com/en_gl/locations'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Our locations
                  </a>
                </li>
              </ul>
            </div>
            <div className='footer-social'>
              <ul>
                <li>
                  <a
                    href='http://www.facebook.com/pages/Ernst-Young/195665063800329'
                    target='blank'
                    rel='noreferrer'
                  >
                    <Facebook />
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.linkedin.com/company/1073'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <LinkedIn />
                  </a>
                </li>
                <li>
                  <a
                    href='http://www.youtube.com/ernstandyoungglobal'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Youtube />
                  </a>
                </li>
                <li>
                  <a
                    href='http://twitter.com/EYnews'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Twitter />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='footer-trade'>
          <p>
            EY refers to the global organization, and may refer to one or more,
            of the member firms of Ernst &amp; Young Global Limited, each of
            which is a separate legal entity. Ernst &amp; Young Global Limited,
            a UK company limited by guarantee, does not provide services to
            clients.
          </p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
